@mixin appbar {
  position: fixed;
  height: 10%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  border-bottom: #e6ebef 1px solid;
  background-color: #fecc4b !important;
}

.open {
  @include appbar;
  left: 20%;
  width: 80%;
}

.close {
  @include appbar;
  left: 4%;
  width: 96%;
}
