@mixin drawer {
    box-shadow: 0px 0px 1px rgb(77, 77, 77);
    position: fixed;
    background-color: #03101A;
    height: 100vh;
    overflow-y: scroll;

    .navigation_selected {
        background-color: #0096d7;
        
        .title {
            color: #fff;
            font-size: 14px !important;
            font-weight: 600;
        }
    }

    .navigation_noselected {
        .title {
            color: #fff;
            font-size: 14px !important;
        }
    }

    .logo {
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & img {
            width: 60%;
        }
    }
}


/*------------------------------------*/

.icons {
    color: #fff;
    font-size: 20px;
}

.link_section {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #2a3a4696;
    padding: 10px 2px;
}


.inner_link_section {
    display: flex !important;
    align-items: center;
    padding: 1% 2% 1% 17%;
    cursor: pointer;
    color: #fff;
    border-bottom: 1px solid #2a3a462d;
}


.inner_sub_link_section {
    display: flex !important;
    align-items: center;
    padding: 1% 2% 1% 22%;
    border-bottom: 1px solid #2a3a462d;
}


.active_sub_link_title {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.inactive_sub_link_title {
    color: #fff !important;
    font-size: 14px !important;
}


.active_link {
    text-decoration: none;
    color: #fff !important;
    cursor: pointer;
}

.inactive_link {
    text-decoration: none;
    color: #fff !important;
    cursor: pointer;
}


.open {
    @include drawer();
    width: 20%;
}

.close {
    @include drawer();
    width: 4%;
}