@mixin loginMixin {
    display: flex;
    align-items: center;
    justify-content: center;
}


.login {
    @include loginMixin;
    background-color: #fff;

    .innerLogin {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.selectedOTPCard {
    background-color: #d5ebf8 !important;
}


