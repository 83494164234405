@import "./mixin/themeMixin.scss";

// modal
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// divider
.divider1 {
  width: 4px;
  height: 25px;
  border-radius: 20;
  background: #0a5fb4;
}

// divider
.divider2 {
  width: 5px;
  height: 25px;
  border-radius: 20;
  background: #05141f;
}

// divider
.divider3 {
  width: 1px;
  height: 25px;
  border-radius: 20;
  background: #9ebcd3;
}

// card
.card1 {
  border: 1px solid #87a8c1;
  padding: 10px !important;
  border-radius: 5px !important;
}

.card2 {
  border: 1px solid rgb(197, 227, 250);
  padding: 10px !important;
  border-radius: 5px !important;
  box-shadow: 0px 3px 10px 0px rgb(197, 227, 250, 0.75) !important;
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// card
.card3 {
  border: 1px solid #5b7d9a;
  padding: 5px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.card4 {
  background-color: #dce9f8 !important;
  border: 1px solid rgb(197, 227, 250);
  padding: 10px !important;
  border-radius: 5px !important;
  box-shadow: 0px 3px 10px 0px rgb(197, 227, 250, 0.75) !important;
  height: 85px;
}

.card5 {
  background-color: #fff9e3 !important;
  border: 1px solid rgb(197, 227, 250);
  padding: 10px !important;
  border-radius: 5px !important;
  box-shadow: 0px 3px 10px 0px rgb(197, 227, 250, 0.75) !important;
  height: 85px;
}

// link
.link {
  color: #05141f;
  text-decoration: none;
}

// link
.link_light {
  color: #ffffff;
  text-decoration: none;
}

// button
.btn1 {
  background-color: #ebf2f6 !important;
  color: #fff !important;
  box-shadow: none !important;
  font-weight: 600 !important;
}

// button
.btn2 {
  background-color: #e5e8eb !important;
  color: #333f4a !important;
  border-radius: 5px !important;
  padding: 0 !important;
}

// button
.btn3 {
  :disabled {
    background-color: #d9eaf6 !important;
  }
  :active {
    background-color: #0caeeb !important;
  }

  box-shadow: none !important;
  color: #000 !important;
  border-radius: 5px !important;
  padding: 2px !important;
}

// accordion
.accordion1 {
  border: 1px solid rgb(197, 227, 250);
  border-radius: 5px !important;
  color: #000 !important;
}

// no data
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & img {
    width: 60%;
  }
}

// datagrid
.MuiDataGrid-columnHeader {
  background-color: #198cb6 !important;
  height: 40px !important;
  color: #fff;
  border: 0.5px solid #198cb6 !important;
}

.MuiDataGrid-columnHeaderCheckbox {
  .MuiSvgIcon-root {
    color: #fff !important;
  }
}

.MuiDataGrid-columnsContainer,
.MuiDataGrid-cell {
  border: 0.5px solid #f0f3f7 !important;
}

.MuiDataGrid-sortIcon,
.MuiDataGrid-menuIconButton {
  color: #fff;
}

// title section -------------------------->
.title_section {
  background-color: #05141f;
  padding: 4px 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: "";
    width: 4px;
    height: 25px;
    border-radius: 20px !important;
    background: #465a68;
    position: absolute;
    left: 0%;
  }
}

// dropdown

.dropdown-btn{
  background-color: #E7F8FF;
  border-radius: 20px;
  color: #282828 !important;
  padding: 0 20px 0 20px;
  margin-bottom:10px !important;
  margin-top:5px !important;
  width: 100px;
  border: 1px solid #282828 !important;
}

.reset-btn{
  background-color: #E7F8FF;
  border-radius: 20px;
  color: #282828 !important;
  padding: 0 20px 0 20px;
  margin-bottom:10px !important;
  margin-top:5px !important;
  width: 100px;
}

// custome cards
.customCard1 {
  @include homeCarMixin(#fff, 0px 2px 0px 3px #bccbf3, 0px 2px 0px 3px #a1b6ee);
}

.customCard2 {
  @include homeCarMixin(#fff, 0px 2px 0px 3px #eee47f, 0px 2px 0px 3px #f7ee74);
}

.customCard3 {
  @include homeCarMixin(#fff, 0px 2px 0px 3px #84e3b5, 0px 2px 0px 3px #54c4a4);
}

.customCard4 {
  @include homeCarMixin(#fff, 0px 2px 0px 3px #fca28f, 0px 2px 0px 3px #e65b5d);
}

.customCard5 {
  @include homeCarMixin2(
    #ecfdfa,
    1px solid rgb(197, 227, 250),
    0px 2px 0px 3px #54c4a4
  );
}

.customCard6 {
  @include homeCarMixin2(
    #fffaec,
    1px solid rgb(197, 227, 250),
    0px 2px 0px 3px #ffe8aa
  );
}

.customCard7 {
  @include homeCarMixin3(#fff, 0px 2px 0px 3px #bccbf3, 0px 2px 0px 3px #a1b6ee);
}

