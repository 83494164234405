.profileSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E7F2FC !important;

    & img {
        border-radius: 100%;
        height: 170px;
    }
}