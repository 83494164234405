.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fecc4b;
  height: 100vh;
  .info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    .header-content {
      color: #000;
      font-size: 30px;
      line-height: 46px;
      text-align: center;
    }
    .body-content {
      color: #424242;
      font-size: 24px;
      line-height: 26px;
    }
  }
}
