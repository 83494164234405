@mixin body {
  position: absolute;
  top: 12%;
  z-index: -1;
  padding: 0px 0px 30px 0px;
  transition-duration: 0.2s;
}

.open {
  @include body();
  left: 22%;
  width: 76%;
}

.close {
  @include body();
  left: 6%;
  width: 92%;
}
