@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

* {
  padding: 0;
  margin: 0;
  background-size: border-box;
}

body,
h6,
h5,
h4,
h3,
h2,
h1,
p {
  font-family: kia-signature-regular, Arial, serif;
  // font-weight: 600;
}

body {
  background-color: #f2f6fc !important;
}

// scrollbar
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-color: #f2f6fc;
}

::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 2px;
}
