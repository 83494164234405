/* filter section  */
.chip-filters {
  .chip {
    margin: 2px;
  }
}

/* add form module */
.add-form {
  margin-top: 20px;

  .outer-field-section {
    width: 100%;
  }

  .instruction-main {
    padding: 20px;
    .instructions {
      margin-left: 40px;
    }
  }

  .form-main {
    margin-top: 50px;
    padding: 20px;
  }
}
